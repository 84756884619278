import { Link } from "gatsby";
import * as React from "react";
import { useWeb3Provider } from "../../contexts/AppContext";
import logoThePolacy from "../../images/logo-polacy.svg";
import { trimAddress } from "../../utils";
import { AnnounceMarquee } from "../AnnounceMarquee/AnnonuceMarquee";
import { Anchor } from "../Items/NavAnchor";
import { BurgerSVG, CloseSVG } from "../Svgs";
import "./DashboardNavbar.scss";

export const DashboardNavbar = ({ serverData, navbarDisabled }: any) => {
  const webProvider = useWeb3Provider();
  const [showMobileNav, setShowMobilNav] = React.useState<boolean>();
  const [stickyClass, setStickyClass] = React.useState("relative");

  const [currentActive, setCurrentActive] = React.useState("");

  React.useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  // const stickNavbar = () => {
  //   if (window !== undefined) {
  //     let windowHeight = window.scrollY;
  //     windowHeight > 100
  //       ? setStickyClass(
  //           "fixed top-0 left-0 z-20 shadow-[0px_0px_20px_rgba(0,0,0,0.8)] border-b-4 border-solid border-black"
  //         )
  //       : setStickyClass("relative");
  //   }
  // };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100
        ? setStickyClass("stick-navbar__scrolled stick-navbar z-20")
        : setStickyClass("");
    }
  };

  return (
    <>
      {" "}
      <AnnounceMarquee serverData={serverData} />
      <div className="mobile-menu-container dashboard-navbar">
        <Link className="logo-container dashboard-navbar__logo" to="/">
          <img src={logoThePolacy} alt="ThePolacyNFT logo" />
        </Link>
        <button
          className="dashboard-navbar__button mobile-menu-button"
          onClick={() => setShowMobilNav(true)}
        >
          <BurgerSVG />
        </button>
      </div>
      <nav className={`mobile-nav-container ${showMobileNav ? "" : "hidden"}`}>
        <button
          className="close-nav-btn"
          onClick={() => setShowMobilNav(false)}
        >
          <CloseSVG />
        </button>
        <Link to="/" className="mobile-menu-open-logo">
          <img src={logoThePolacy} alt="ThePolacyNFT logo" />
        </Link>
        <Anchor
          id="my-polacy"
          title="Moi Polacy"
          onClickAction={() => {
            setCurrentActive("my-polacy");
          }}
          isActive={currentActive === "my-polacy"}
          disabledButton={navbarDisabled}
        />
        <Anchor
          id="drops"
          title="Dropy"
          onClickAction={() => {
            setCurrentActive("drops");
          }}
          isActive={currentActive === "drops"}
          disabledButton={navbarDisabled}
        />
        <Anchor
          id="timetable"
          title="Wydarzenia"
          onClickAction={() => {
            setCurrentActive("timetable");
          }}
          isActive={currentActive === "timetable"}
          disabledButton={navbarDisabled}
        />

        <Anchor
          id="blog"
          title="Blog"
          onClickAction={() => {
            setCurrentActive("blog");
          }}
          isActive={currentActive === "blog"}
          disabledButton={navbarDisabled}
        />
        <Anchor
          id="sasiedzi"
          title="Sąsiedzi"
          onClickAction={() => {
            setCurrentActive("sasiedzi");
          }}
          isActive={currentActive === "sasiedzi"}
          disabledButton={navbarDisabled}
        />

        <a
          className="red-btn"
          href="#"
          onClick={async () => {
            if (webProvider.accountDetails?.address !== undefined) {
              await webProvider.handleLogout();
            } else {
              await webProvider.handleLogin();
            }
          }}
        >
          {webProvider.accountDetails?.address !== undefined
            ? `${trimAddress(webProvider.accountDetails.address)}`
            : "Połącz"}
        </a>
      </nav>
      <section
        className={`desktop-nav-container ${stickyClass} dashboard-navbar__section`}
      >
        <div
          className="nav-container dashboard-navbar__box"
          id="navbar-default"
        >
          <nav className="md:flex md:flex-row dashboard-navbar__navigation">
            <Anchor
              id="my-polacy"
              title="Moi Polacy"
              onClickAction={() => {
                setCurrentActive("my-polacy");
              }}
              isActive={currentActive === "my-polacy"}
              disabledButton={navbarDisabled}
            />
            <Anchor
              id="drops"
              title="Dropy"
              onClickAction={() => {
                setCurrentActive("drops");
              }}
              isActive={currentActive === "drops"}
              disabledButton={navbarDisabled}
            />

            <Anchor
              id="timetable"
              title="Wydarzenia"
              onClickAction={() => {
                setCurrentActive("timetable");
              }}
              isActive={currentActive === "timetable"}
              disabledButton={navbarDisabled}
            />
          </nav>
          <Link to="/">
            <div className="logo-container">
              <img src={logoThePolacy} alt="ThePolacyNFT logo" />
            </div>
          </Link>
          <nav className="dashboard-navbar__second-navigation">
            <Anchor
              id="blog"
              title="Blog"
              onClickAction={() => {
                setCurrentActive("blog");
              }}
              isActive={currentActive === "blog"}
              disabledButton={navbarDisabled}
            />
            <Anchor
              id="sasiedzi"
              title="Sąsiedzi"
              onClickAction={() => {
                setCurrentActive("sasiedzi");
              }}
              isActive={currentActive === "sasiedzi"}
              disabledButton={navbarDisabled}
            />
            <a
              className="red-btn btn-clicked"
              href="#"
              onClick={async () => {
                webProvider.accountDetails?.address === undefined
                  ? await webProvider.handleLogin()
                  : await webProvider.handleLogout();
              }}
            >
              {webProvider.accountDetails?.address !== undefined
                ? trimAddress(webProvider.accountDetails.address)
                : "Połącz"}
            </a>
          </nav>
        </div>
      </section>
    </>
  );
};
