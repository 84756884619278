import * as React from "react";
import type { HeadFC } from "gatsby";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import { Blog, BlogPostType } from "../services/Blog";
import { BlogPostItem } from "../components/BlogPostItem/BlogPostItem";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { SEO } from "../components/SEO";

const IndexPageComponent = ({ serverData }: { serverData: BlogPostType[] }) => {
  const web3Provider = useWeb3Provider();
  return (
    <Web3Providers>
      <main className="blog-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <NavBar />
        )}

        <section className="blog-posts blog-view__section p-5">
          <iframe
            className="mx-auto"
            width={"100%"}
            height="500px"
            src="https://gra.thepolacy.pl/"
          />
          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPageComponent;

// export const Head: HeadFC = () => <title>Blog</title>;
export const Head: HeadFC = () => <SEO subtitle="Blog" />;

export async function getServerData() {
  try {
    const response = await Blog.getBlogPosts();
    if (response instanceof Error) throw response;
    return { props: response };
  } catch (err) {
    console.error(err);
    return {};
  }
}
